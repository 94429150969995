import { Button, Link } from "@/components/atoms";
import Close from "@/icons/Close";

import clsx from "clsx";
import Image from "next/image";

import { CartItemEngraving } from "@/components/molecules/Engraving/CartItemEngraving";
import { GiftWrappingSummary } from "@/components/molecules/GiftWrapping/GiftWrappingSummary";
import { PATHS } from "@/consts";
import { ICartItem } from "@/lib/centra/formatters/types";
import useRemoveFromCart from "@/lib/hooks/useSelection/cart/removeFromCart";
import { useTranslations } from "next-intl";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import styles from "./cart-item.module.css";

interface ICartItemProps {
  item: ICartItem;
  isFinalized?: boolean;
}

const CartItemPrice = ({
  discountedPrice,
  price,
  priceAsNumber,
}: {
  discountedPrice?: string;
  price?: string;
  priceAsNumber?: number;
}) => {
  const isDiscounted = discountedPrice !== price;
  const t = useTranslations();
  return (
    <div className={clsx(styles.discounted__wrapper)}>
      {isDiscounted && (
        <span className={styles.discounted__price}>{discountedPrice}</span>
      )}
      <p className={styles.regular__price}>
        {priceAsNumber === 0 ? t("checkout.summary.free") : price}
      </p>
    </div>
  );
};

const CartImage = ({ item }: ICartItemProps) => {
  return (
    <div className={styles.image}>
      <Image
        src={item?.media?.full?.href || ""}
        width={64}
        height={85}
        alt={""}
      />
    </div>
  );
};

const CartItemQuantity = ({ item }: ICartItemProps) => {
  if (!item) return null;

  // Items with engraving should not be able to change quantity
  if (item.engraving) return <div />;

  return (
    <div className={styles.quantity__wrapper}>
      <QuantitySelector
        quantity={item.quantity}
        totalProductQuantity={item.totalProductQuantity || item.quantity}
        line={item.line}
      />
    </div>
  );
};

const DeleteCartItem = ({ item }: ICartItemProps) => {
  const t = useTranslations();
  const { removeItems } = useRemoveFromCart();

  const remove = (item: ICartItem) => {
    // If there is engraving assigned - it is displayed as single, separated item
    if (item.engraving?.relatedItem === item.itemId) {
      return removeItems.mutate([
        {
          line: item?.engraving.engravingId,
          numberOfItemsToRemove: 1,
        },
        {
          line: item.id,
          numberOfItemsToRemove: 1,
          totalProductQuantity: item.totalProductQuantity,
        },
      ]);
    } else {
      return removeItems.mutate([
        {
          line: item.id,
          numberOfItemsToRemove: item.quantity,
          totalProductQuantity: item.totalProductQuantity,
        },
      ]);
    }
  };

  if (!item) return null;

  return (
    <Button color="simple" onClick={() => remove(item)}>
      <span>
        <span>{t("components.cartItem.removeButton")}</span>
        <Close />
      </span>
    </Button>
  );
};

const CartItem = ({ item, isFinalized }: ICartItemProps) => {
  const t = useTranslations();

  const href = `${PATHS.product}/${item.uri}`;
  const isGiftWrapping = item.giftWrapping;
  return (
    <div className={styles.item}>
      {!isGiftWrapping ? (
        <Link href={href} aria-label="Go to item">
          <CartImage item={item} />
        </Link>
      ) : (
        <CartImage item={item} />
      )}

      <div className={styles.details__container_wrap}>
        <div className={styles.item__info}>
          <div className={styles.details__container}>
            <div className={styles.left__column}>
              {!isGiftWrapping ? (
                <Link href={href}>
                  <p className={styles.item__name}>{item?.series?.value}</p>
                </Link>
              ) : (
                <p className={styles.item__name}>{item?.series?.value}</p>
              )}

              <div className={styles.item__meta}>
                <span>{item?.shape?.value}</span>
              </div>
              <div className={styles.item__meta}>
                {!isGiftWrapping && (
                  <>
                    <span>{item.variant}</span>, <span>{item.size}</span>{" "}
                  </>
                )}
              </div>
            </div>

            <div className={styles.right__column}>
              <CartItemPrice
                discountedPrice={item.discountedPrice}
                price={item.price}
                priceAsNumber={item.priceAsNumber}
              />
            </div>
          </div>

          {item.engravingEnabled && (
            <CartItemEngraving item={item} isFinalized={isFinalized} />
          )}
        </div>

        <div className={styles.item__actions}>
          {isFinalized ? (
            isGiftWrapping ? (
              <GiftWrappingSummary item={item} />
            ) : (
              <span>
                {t("components.cartItem.quantity", { quantity: item.quantity })}
              </span>
            )
          ) : (
            <>
              {isGiftWrapping ? (
                <GiftWrappingSummary item={item} />
              ) : (
                <CartItemQuantity item={item} />
              )}
              <DeleteCartItem item={item} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
