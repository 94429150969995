import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";
import type { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";

import env from "@/env.mjs";
import { GiftDetailsSchema } from "@/lib/checkout/GiftWrapping/schemas";
import {
  generateCartSummary,
  getFirstImage,
  getSelectionItemCategory,
  getSelectionItemCategoryName,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
  getSelectionProductId,
} from "../selectionAtoms";
import { Engraving, GroupedProducts, ICartItem } from "./types";

const generateCartItem = (item: CentraSelectionItem) => {
  const itemId = getSelectionItemId(item) ?? "";
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const shape = item?.product?.pr_shape;
  const series = item?.product?.pr_series;
  const engravingEnabled = item?.product?.pr_engraving_key !== "1";
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item) ?? 0;
  const price = getSelectionItemPriceEach(item) ?? "";
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item) ?? "";
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const category = getSelectionItemCategory(item) ?? "";
  const categoryName = getSelectionItemCategoryName(item) ?? "";
  const productId = getSelectionProductId(item) ?? "";
  const comment = item.comment;
  // const media = getFirstImage(item);
  const media = {
    standard: getFirstImage(item, "standard"),
    full: getFirstImage(item, "full"),
  };

  // TODO: why do we need both line and id if they have the same value?
  const id = getSelectionItemLine(item) ?? "";
  const line = item.line ?? "";

  return {
    name,
    series,
    shape,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    priceAsNumber,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
    categoryName,
    productId,
    engravingEnabled,
    comment,
  };
};

const extractEngravingsFromProducts = (items: CentraSelectionItem[]) =>
  items.reduce<GroupedProducts>(
    (acc, item) => {
      if (!item) return acc;

      if (getSelectionItemId(item) === env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID) {
        const comment = JSON.parse(item.comment);
        const engravingId = item.line;

        const engraving: Engraving = {
          item: comment?.comment?.item || "",
          relatedItem: comment?.comment?.relatedItem || "",
          txt: comment?.comment?.txt || "",
          engravingId: engravingId || "",
          price: item.priceEachAsNumber,
        };

        return { ...acc, engravings: [...acc.engravings, engraving] };
      } else {
        return { ...acc, products: [...acc.products, generateCartItem(item)] };
      }
    },
    { engravings: [], products: [] },
  );

const getProductsGroupedByEngraving = (
  products: ICartItem[],
  engravings: Engraving[],
) =>
  products.reduce<ICartItem[]>((acc, cartItem) => {
    const matchingEngravings = engravings.filter(
      ({ relatedItem }) => relatedItem === cartItem.itemId,
    );

    const numberOfMatchingEngravings = matchingEngravings.length;
    const quantity = cartItem?.quantity || 1;

    const quantityWithoutEngraving = quantity - numberOfMatchingEngravings;

    const productsWithoutEngravings =
      quantityWithoutEngraving > 0
        ? [
            {
              ...cartItem,
              quantity: quantityWithoutEngraving,
              totalProductQuantity: quantity,
            },
          ]
        : [];

    const separatedEngravings = matchingEngravings.reduce<ICartItem[]>(
      (acc, engraving) => {
        return [
          ...acc,
          {
            ...cartItem,
            engraving: engraving,
            quantity: 1,
            totalProductQuantity: quantity,
          },
        ];
      },
      [],
    );

    return [...acc, ...productsWithoutEngravings, ...separatedEngravings];
  }, []);

/**
 * Will fill out the "giftWrapping" property on each item in the cart if relevant
 * @param products
 * @returns
 */
export const formatGiftWrappingItems = (products: ICartItem[]): ICartItem[] => {
  const GIFT_WRAPPING_CATEGORY_ID = env.NEXT_PUBLIC_CENTRA_GIFT_CATEGORY_ID;
  try {
    return products.map((product) => {
      if (product.category !== GIFT_WRAPPING_CATEGORY_ID) return product;
      if (!product.comment) return product;

      const comment = JSON.parse(product.comment);
      const parsed = GiftDetailsSchema.safeParse(comment);

      if (!parsed.success) {
        return product;
      }

      return {
        ...product,
        giftWrapping: parsed.data.comment.txt,
      };
    });
  } catch (error) {
    return products;
  }
};

export const formatItems = (items: CentraSelectionItem[]) => {
  const { engravings, products } = extractEngravingsFromProducts(items);
  const productsGroupedByEngraving = getProductsGroupedByEngraving(
    products,
    engravings,
  );
  const giftWrappingItems = formatGiftWrappingItems(productsGroupedByEngraving);

  return giftWrappingItems;
};

export const formatSelection = (data: CentraSelection) => {
  const items = data.selection?.items ?? [];

  const { engravings, products } = extractEngravingsFromProducts(items);
  const productsGroupedByEngraving = formatItems(items);

  const generatedSummary = generateCartSummary(data);

  const summary = {
    ...generatedSummary,
    totalQuantity: data.selection?.totals?.totalQuantity
      ? data.selection?.totals?.totalQuantity - engravings.length
      : 0,
  };

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: productsGroupedByEngraving,
      products,
      summary,
    },
    // Keep original selection for optimistic update changes
    rawSelection: data.selection,
  };

  return formattedData;
};

export type FormattedSelection = ReturnType<typeof formatSelection>;

export default formatSelection;
