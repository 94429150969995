import type { BlokComponents } from "@/lib/bloks/blok-component-types";

import { PlpBannerCard } from "@/components/molecules/PlpBanner/PlpBanner";
import { RecommendedProductCards } from "@/components/molecules/Search/RecommendedProducts";
import { ButtonAtom } from "./atoms/Button/Button";
import Link from "./atoms/Link/Link";
import MediaElement from "./atoms/MediaElement";
import ReusableContent from "./atoms/ReusableContent/ReusableContent";
import Article from "./layout/Article/Article";
import ArticleCategory from "./layout/ArticleCategory/ArticleCategory";
import Plp from "./layout/PLP";
import Page from "./layout/Page/Page";
import StorePage from "./layout/StorePage/Page";
import Accordion from "./sections/Accordion";
import ArticleSection from "./sections/ArticleSection";
import Body from "./sections/Body";
import Button from "./sections/Button/Button";
import Content from "./sections/Content";
import ContentSection from "./sections/ContentSection";
import Form from "./sections/Form/Form";
import Hero from "./sections/Hero/Hero";
import Media from "./sections/Media";
import MediaGallery from "./sections/MediaGallery/MediaGallery";
import NewsletterSignup from "./sections/NewsletterSignup/NewsletterSignup";
import ProductListing from "./sections/ProductListing";
import { RetailersBlok } from "./sections/Retailers";
import VideoBlok from "./sections/Video/Media";
import HeaderBloks from "./statics/header/HeaderBloks";
import MegaHeaderBloks from "./statics/header/MegaHeaderBloks";

const components: BlokComponents = {
  header: HeaderBloks,
  megaHeader: MegaHeaderBloks,
  page: Page,
  mediaGallery: MediaGallery,
  productListing: ProductListing,
  articleSection: ArticleSection,
  contentSection: ContentSection,
  hero: Hero,
  reusableContent: ReusableContent,
  reusable: Page,
  media: Media,
  mediaElement: MediaElement,
  video: VideoBlok,
  content: Content,
  body: Body,
  Article: Article,
  productListingPage: Plp,
  button: Button,
  newsletterSignup: NewsletterSignup,
  store: StorePage,
  articleCategory: ArticleCategory,
  accordion: Accordion,
  link: Link,
  buttonAtom: ButtonAtom,
  form: Form,
  retailers: RetailersBlok,
  recommendedProducts: RecommendedProductCards,
  plpBanner: PlpBannerCard,
};

export default components;
